<template>
  <OCColumn class="conversations-list-container">
    <CLoading v-if="loading"></CLoading>
    <div v-if="!loading && messages && messages.length === 0">
      No conversations.
    </div>
    <div v-if="!success">
      Error loading conversations. Please try again later.
    </div>
    <OCUncontainer v-if="messages && messages.length > 0">
      <ul class="conversations-list-list">
        <li class="conversations-list-item" v-for="message in messages" :key="message.logid">
          <CConversationListItem :message="message" @remove="onRemoveItem(message.target)"/>
        </li>
      </ul>
    </OCUncontainer>
  </OCColumn>
</template>

<script>
import { listConversations } from '@/services/anonymousTexting'
import CConversationListItem from './CConversationListItem'
import CLoading from '@/components/CLoading'
import OCColumn from '@/objects/containers/OCColumn'
import OCUncontainer from '@/objects/containers/OCUncontainer'
import { deleteConversation } from '../../services/anonymousTexting'

export default {
  name: 'CConversationsList',
  components: { OCUncontainer, OCColumn, CLoading, CConversationListItem },
  data: () => ({
    loading: false,
    success: true,
    /**
     * @type {import('../services/anonymousTexting').Message[]}
     */
    messages: null
  }),
  methods: {
    async load () {
      this.loading = true
      this.success = true
      try {
        const { success, data: { logs } } = await listConversations({
          user: this.$store.getters.getEmail,
          pass: this.$store.getters.getPassword
        })
        this.success = success
        this.messages = logs
      } catch (e) {
        this.success = false
      } finally {
        this.loading = false
      }
    },
    async onRemoveItem (target) {
      if (!window.confirm(`Are you sure you want to delete conversation with ${target}? All messages will be lost!`)) {
        return
      }
      this.loading = true
      this.success = true
      try {
        await deleteConversation({
          user: this.$store.getters.getEmail,
          pass: this.$store.getters.getPassword,
          target
        })
      } catch (e) {
        await this.load()
      } finally {
        await this.load()
      }
    }
  },
  mounted () {
    this.load()
  }
}
</script>

<style lang="scss" scoped>
.conversations-list {
  &-container {
    flex-grow: 1;
  }

  &-list {
    list-style: none;
    margin: 0;
    padding: 0;
    @include two-column() {
      // accommodate the share-this bar on the right
      padding: 0 ($share-this-width + $list-margin) 0 $list-margin;
    }
  }

  &-item {
    border-bottom: 1px solid #7F7F7F;
  }
}
</style>
