<template>
  <OContainer class="conversation-item-root">
    <OButton class="conversation-item-remove" theme="outline-primary" @click="$emit('remove', this)">✕</OButton>
    <OLink class="conversation-item-container" :to="{ name: 'messages', params: { target: message.target } }">
      <div class="conversation-item-number">{{ message.target }}</div>
      <div class="conversation-item-message" :title="displayMessage">{{ displayMessage }}</div>
      <div class="conversation-item-date">{{ displayDate }}</div>
    </OLink>
  </OContainer>
</template>

<script>
import { differenceInCalendarDays, format } from 'date-fns'
import OLink from '../../objects/OLink'
import OContainer from '../../objects/containers/OContainer'
import { mapGetters } from 'vuex'
import OButton from '../../objects/OButton'

export default {
  name: 'CConversationListItem',
  components: { OButton, OContainer, OLink },
  props: {
    message: {
      type: Object, required: true
    }
  },
  computed: {
    ...mapGetters({
      credits: 'getCredits'
    }),
    displayDate () {
      const date = new Date(this.message.when * 1000)
      const daysDiff = differenceInCalendarDays(date, new Date())
      if (daysDiff === 0) {
        return format(date, 'p')
      } else if (daysDiff === 1) {
        return 'yesterday'
      } else return format(date, 'P')
    },
    displayMessage () {
      const status = this.message.status
      const type = this.message.scenario
      if (status === 'hidden' && this.credits === 0) {
        return '*** Message received but hidden, since you do not have enough credits. Please, purchase to reveal hidden messages ***'
        // return this.message.text.replaceAll(/[\d\w]/g, '*')
      } else {
        switch (type) {
          case 'atext':
            return this.message.text
          case 'mms':
            return 'Multimedia message'
          case 'call':
            if (this.message.direction === 'outgoing') {
              return 'Outgoing Call'
            } else if (this.message.status === 'no-answer') {
              return 'Missed Call'
            }
            return 'Incoming Call'
        }
      }
      return 'Unsupported message type'
    }
  }
}
</script>

<style lang="scss" scoped>
.conversation-item {
  &-container {
    display: block;
    color: #7F7F7F;
    font-size: 1rem;
    padding: 1em 0;
    @include two-column {
      // remove the padding due to share-this from list
      // so the container inside item can align with rest of the page
      margin: 0 (-($share-this-width + $list-margin)) 0 (- $list-margin);
    }
  }

  &-number {
    color: #0F0F0F;
    font-size: 1.2em;
  }

  &-message {
    overflow: hidden;
    text-transform: none;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-remove {
    float: right;
    height: 100%;
    width: 50px;
    padding: 0;
    font-size: 2em;
    line-height: 3*1.4rem + 2rem;
    border-color: transparent;
  }

  @media (pointer: fine) {
    &-remove {
      display: none;
    }
    &-root:hover &-remove {
      display: block;
    }
  }

  &-root {
    &:after {
      content: "";
      clear: both;
      display: table;
    }
  }
}
</style>
